import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudDownloadAlt, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {saveAs} from "file-saver";

import {GET_RESUME_STATUS} from "../../redux/reducers/ResumeDownloadReducer.jsx";
import {getResume} from "../../redux/actions/ResumeDownloadActions.jsx";

class ResumeDownloader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: faCloudDownloadAlt
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.props.downloadResume()
      .then(() => this.setState({icon: faCloudDownloadAlt}))
      .catch((err) => this.setState({icon: faExclamationTriangle}));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.status !== this.props.status && this.props.status === GET_RESUME_STATUS.COMPLETE && this.props.payload) {
      saveAs(this.props.payload, "lamdav_resume.pdf");
    }
  }

  render() {
    return (
      <button className="p-link" onClick={this.handleClick} title="Download Resume">
        <FontAwesomeIcon icon={this.state.icon}/>
      </button>
    );
  }
}

ResumeDownloader.propTypes = {
  status: PropTypes.string,
  payload: PropTypes.object,
  downloadResume: PropTypes.func.isRequired
};

const mapStateToProps = (state = {status: GET_RESUME_STATUS.NOT_STARTED}, action) => {
  return {
    status: state.status,
    payload: state.payload
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadResume: () => {
      return dispatch(getResume());
    }
  };
};

const ConnectedResumeDownloader = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResumeDownloader);

export default ConnectedResumeDownloader;
