import BackendService from "../../services/BackendService.jsx";

const service = new BackendService();

export const GET_RESUME = "GET_RESUME";
export const getResume = () => {
  return {
    type: GET_RESUME,
    payload: service.downloadResume()
  };
};
