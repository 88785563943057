import axios from "axios";

const baseURL = process.env.REACT_APP_API_ENDPOINT;

class BackendService {
  constructor() {
    this.client = axios.create({baseURL: baseURL});
  }

  downloadResume() {
    return this.client.get("/resume", {responseType: "blob"})
      .then((response) => response.data);
  }

  getBlogPost() {
    return this.client.get("/blog")
      .then((response) => response.data);
  }

  getBlogContent(name) {
    return this.client.get(`/blog/${name}`)
      .then((response) => response.data);
  }

  sendEmail(email, subject, message) {
    return this.client.put("/mail", {email, subject, message})
      .then((response) => response.data);
  }
}

export default BackendService;
