import {GET_RESUME} from "../actions/ResumeDownloadActions.jsx";

export const GET_RESUME_STATUS = {
  PENDING: "PENDING",
  COMPLETE: "COMPLETE",
  FAILURE: "FAILURE",
  NOT_STARTED: "NOT_STARTED"
};

/**
 *  Note: actions are the objects emitted by the action creator.
 *  Use the type field to manipulate it. Redux-Promise middleware
 *  Adds additional typing information to indicate Promise state.
 *
 *  @param {object} state current state
 *  @param {object} action incoming action
 *  @return {object} the next state with an updated status.
 **/
export const resumeDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case `${GET_RESUME}_PENDING`:
      return {status: GET_RESUME_STATUS.PENDING};
    case `${GET_RESUME}_FULFILLED`:
      return {status: GET_RESUME_STATUS.COMPLETE, payload: action.payload};
    case `${GET_RESUME}_REJECTED`:
      return {status: GET_RESUME_STATUS.FAILURE, payload: action.payload};
    default:
      return {status: GET_RESUME_STATUS.NOT_STARTED};
  };
};
